import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class ServiceSlide extends Component {
 render() {
  return (
   <div className="row-services">
    <div className="container">
     <div className="col-md-12">
      <div
       className="themesflat-spacer clearfix"
       data-desktop={81}
       data-mobile={60}
       data-smobile={60}
      />
      <div
       className="themesflat-carousel-box data-effect clearfix"
       data-gap={30}
       data-column={3}
       data-column2={2}
       data-column3={1}
       data-auto="true">
       <div className="owl-carousel owl-theme">
        <div className="themesflat-image-box style-2 clearfix">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/indyc.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Sporting Events</Link>
            </h5>
            <p className="letter-spacing-01">
             Goldstar handles the parking for the Rev event and has partnered with Red Frog Events
             to also assist solve their parking needs for the Warrior Dash Race in states across the
             Midwest. We have also worked with the NFL Players Association to provide valet for
             their 2012 Indianapolis Super Bowl party. Every sporting event brings a unique set of
             parking and traffic challenges, but Goldstar has the diverse resources to meet and
             exceed all expectations and facilitate a more enjoyable and expedited arrival/departure
             from each venue.
            </p>
           </div>
          </div>
         </div>
        </div>
        <div className="themesflat-image-box style-2 clearfix ">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/wed.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Private Events</Link>
            </h5>
            <p className="letter-spacing-01">
             Goldstar has partnered with a dynamic list of clients to provide a broad array of
             private-event services. We offer valet, self-parking management, traffic control,
             parking consultation, and shuttle services. Whether you’re planning a wedding, a
             company event, a holiday party, or anything in between, Goldstar will find a sensible
             solution to your parking needs.
            </p>
           </div>
          </div>
         </div>
        </div>
        <div className="themesflat-image-box style-2 clearfix">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/tonys-indy-night-2.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Restaurants</Link>
            </h5>
            <p className="letter-spacing-01">
             Goldstar has restaurant partners all throughout the greater Indianapolis area. We work
             closely with individual clients to create a custom solution to fit their unique parking
             challenges. The Goldstar valet team will enhance the complete experience of each guest
             by eliminating the hassle of parking and extending customer service beyond the door
             facilitating more opportunities for your restaurant to focus on what you do best.
            </p>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div
       className="themesflat-spacer clearfix"
       data-desktop={15}
       data-mobile={15}
       data-smobile={15}
      />
      <div
       className="themesflat-carousel-box data-effect clearfix"
       data-gap={30}
       data-column={3}
       data-column2={2}
       data-column3={1}
       data-auto="true">
       <div className="owl-carousel owl-theme">
        <div className="themesflat-image-box style-2 clearfix">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/slider/mercb-desk.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Traffic Control</Link>
            </h5>
            <p className="letter-spacing-01">
             Our team will ensure your event runs smoothly while you focus your attention on the
             details of your event. We are equipped with professional traffic control materials that
             will ensure the safety and security of customers.
            </p>
           </div>
          </div>
         </div>
        </div>
        <div className="themesflat-image-box style-2 clearfix">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/parkingLot.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Parking Lot Management</Link>
            </h5>
            <p className="letter-spacing-01">
             Our team members are able to solve complex parking requirements. We will be able to
             assist in maximizing your parking lots and ensure that the guests experience no issues.
            </p>
           </div>
          </div>
         </div>
        </div>
        <div className="themesflat-image-box style-2 clearfix">
         <div className="image-box-item">
          <div className="inner">
           <div className="thumb data-effect-item">
            <img src="assets/img/hotelValet.jpg" alt="altimage" />
            <div className="overlay-effect bg-color-accent" />
           </div>
           <div className="text-wrap">
            <h5 className="heading">
             <Link to="#">Hotels</Link>
            </h5>
            <p className="letter-spacing-01">
             Goldstar’s complete customer service is available 24 hours a day with features such as
             full-service valet, doorman, bellhop, coat-check, concierge, and shuttle services.
             Whether you are looking to change your current provider or upgrade to a more complete
             level of customer care, Goldstar has the resources and industry experience to help you
             achieve your goals.
            </p>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <div
       className="themesflat-spacer clearfix"
       data-desktop={45}
       data-mobile={60}
       data-smobile={60}
      />
     </div>
    </div>
   </div>
  );
 }
}

export default ServiceSlide;
