import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

class BottomBar extends Component {
 constructor(props) {
  super(props);
  this.state = {
   menus: [
    {
     id: 1,
     name: 'Home',
     links: '/'
    },

    {
     id: 2,
     name: 'Services',
     links: '/services'
    },

    { id: 3, name: 'GET A QUOTE', links: '/quote' },

    {
     id: 4,
     name: 'Join Our Team',
     links: '/join'
    },
    {
     id: 5,
     name: 'Clients',
     links: '/clients'
    },
    {
     id: 6,
     name: 'Contact',
     links: '/contact'
    }
   ]
  };
 }

 render() {
  return (
   <div id="bottom" className="clearfix has-spacer">
    <div id="bottom-bar-inner" className="container">
     <div className="bottom-bar-inner-wrap">
      <div className="bottom-bar-content">
       <div id="copyright">
        <span className="text">
         © 2022 Goldstar Management LLC: All Rights Reserved
         <Link to="#" className="text-accent"></Link>{' '}
        </span>
       </div>
      </div>
      {/* /.bottom-bar-content */}
      <div className="bottom-bar-menu">
       <ul className="bottom-nav">
        {this.state.menus.map((data) => (
         <li
          className={
           data.name === this.props.data.names ? 'menu-item current-menu-item' : 'menu-item'
          }
          key={data.id}>
          <Link
           to={data.links}
           onClick={() => {
            window.location.href = data.links;
           }}>
           {data.name}
          </Link>
         </li>
        ))}
       </ul>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

export default withRouter(BottomBar);
