import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class IconBox extends Component {
 constructor(props) {
  super(props);
  this.state = {
   iconbox: [
    {
     id: 1,
     title: 'PHENOMENAL CUSTOMER SERVICE',
     text: `Our drivers know how important it is to be able to work with customers. Our drivers are friendly and they aim at making sure our customers' needs are satisfied.`
    },
    {
     id: 2,
     title: 'ON TIME DELIVERY',
     text:
      'We respect the customer’s time and schedule and always deliver the car as quickly as possible.'
    },
    {
     id: 3,
     title: 'SKILLED DRIVERS',
     text:
      'Our drivers are skilled at driving different types of vehicles. We ensure our drivers are licensed and insured. They are able to manage a large number of cars at busy times. '
    }
   ]
  };
 }

 render() {
  return (
   <div className="row">
    {this.state.iconbox.map((data) => (
     <div className="col-md-4" key={data.id}>
      <div
       className="themesflat-spacer clearfix"
       data-desktop={0}
       data-mobile={0}
       data-smobile={35}
      />
      <div
       className="themesflat-content-box clearfix"
       data-margin="0 5px 0 5px"
       data-mobilemargin="0 0 0 0">
       <div className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 clearfix">
        <div className="icon-wrap">
         <i className={data.icon} />
        </div>
        <div className="text-wrap">
         <h5 className="heading">
          <Link to="#">{data.title}</Link>
         </h5>
         <div className="sep clearfix" />
         <p className="sub-heading">{data.text}</p>
        </div>
       </div>
      </div>
     </div>
    ))}
   </div>
  );
 }
}

export default IconBox;
