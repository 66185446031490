import React, { Component } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';
import picA from '../../chris.jpg';
import picB from '../../lynb.jpg';

class Leadership extends Component {
 constructor(props) {
  super(props);
  this.state = {
   headers: [
    {
     id: 1,
     logoweb: 'assets/img/logos.jpg',
     names: 'Leadership'
    }
   ],
   banners: [
    {
     id: 1,
     links: '',
     titlelink: '',
     border: '',
     name: 'Leadership',
     heading: 'LEADERSHIP'
    }
   ]
  };
 }
 render() {
  return (
   <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
    <div id="wrapper" className="animsition">
     <div id="page" className="clearfix">
      <div id="site-header-wrap">
       <TopBar />
       {this.state.headers.map((data, idx) => (
        <Header data={data} key={idx} />
       ))}
      </div>
      {this.state.banners.map((data) => (
       <BannerTitle key={data.id} data={data} />
      ))}
      <div className="container">
       <div className="themesflat-headings style-2 clearfix">
        <div
         className="themesflat-spacer clearfix"
         data-desktop={36}
         data-mobile={35}
         data-smobile={35}
        />
        <p className={'text-center'}>
         The executive team of Goldstar has over twenty years of combined experience in parking
         solutions throughout Indianapolis and the greater area. Goldstar understands the importance
         of a personal relationship with each customer, large or small.
        </p>
        <div className="sep has-width w100 accent-bg clearfix text-center" />
       </div>
      </div>
      <div id="main-content" className="site-main clearfix">
       <div id="content-wrap">
        <div id="site-content" className="site-content clearfix">
         <div id="inner-content" className="inner-content-wrap">
          <div className="page-content">
           <div className="row-contact">
            <div className="container">
             <div className="row">
              <div className="col-md-12">
               <div
                className="themesflat-spacer clearfix"
                data-desktop={61}
                data-mobile={60}
                data-smobile={60}
               />
              </div>
             </div>
             <div className="row">
              <div className="col-md-6">
               <div className="themesflat-headings style-2 clearfix">
                <div className="container  card">
                 <div
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="800"
                  className={'row justify-content-center'}>
                  <div className="justify-content-left">
                   <div className="text-left">
                    <img src={picA} height={125} width={125} alt="chris" className="" />
                   </div>
                  </div>
                 </div>
                 <h2>Chris Kolditz</h2>
                 <p>President & CEO</p>
                 <p>
                  As the President, and co-founder of Goldstar, Chris brings over 20 years of sales,
                  nearly 15 years of parking management, shutling and valet experience to the table.
                  He is an advocate of building long-term relationships and utilizes his background
                  to help create unique solutions that fit each client’s needs.
                 </p>
                </div>
               </div>
               <div
                className="themesflat-spacer clearfix"
                data-desktop={36}
                data-mobile={35}
                data-smobile={35}
               />

               <div
                className="themesflat-spacer clearfix"
                data-desktop={80}
                data-mobile={60}
                data-smobile={60}
               />
              </div>
              <div className="col-md-6">
               <div className="themesflat-headings style-2 clearfix">
                <div className="container card">
                 <div
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="800"
                  className={'row justify-content-center'}>
                  <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12  d-flex justify-content-center">
                   <div className="">
                    <img className={'cards'} src={picB} height={125} width={125} />
                   </div>
                  </div>
                 </div>
                 <h2>Lyn Kolditz</h2>
                 <p>Owner & CFO</p>
                 <p>
                  As the owner and co-founder of Goldstar, Lyn provides a commitment to quality and
                  service. She understands the importance and value of a personal relationship with
                  each customer that we serve.
                 </p>
                </div>
               </div>
               <div
                className="themesflat-spacer clearfix"
                data-desktop={36}
                data-mobile={35}
                data-smobile={35}
               />

               <div
                className="themesflat-spacer clearfix"
                data-desktop={80}
                data-mobile={60}
                data-smobile={60}
               />
              </div>
              <div className="col-md-6">
               <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
               />
              </div>
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <Footer />
     {this.state.headers.map((data, idx) => (
      <BottomBar data={data} key={idx} />
     ))}
    </div>
   </div>
  );
 }
}

export default Leadership;
