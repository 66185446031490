const bannerhome1 = [
 {
  id: 1,
  srcimg: 'assets/img/lincoln.jpg',
  classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
  classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
  datax: "['left','left','left','center']",
  datahset: "['34','34','34','0']",
  datavset1: "['-134','-134','-134','-134']",
  datavset2: "['-63','-63','-63','-63']",
  datavset3: "['2','2','2','2']",
  datavset: "['106','106','106','106']",
  classbtn: 'tp-caption',
  comment: ' EXCEEDING CUSTOMER EXPECTATIONS'
 },
 {
  id: 2,
  srcimg: 'assets/img/mallCar.jpg',
  classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
  classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
  datax: "['center','center','center','center']",
  datahset: "['0','0','0','0']",
  datavset1: "['-134','-134','-134','-134']",
  datavset2: "['-63','-63','-63','-63']",
  datavset3: "['2','2','2','2']",
  datavset: "['106','106','106','106']",
  classbtn: 'tp-caption text-center',
  comment: 'AFFORDABLE PRICING'
 },
 {
  id: 3,
  srcimg: 'assets/img/premium.jpg',
  classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
  classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
  datax: "['right','right','right','center']",
  datahset: "['-14','-14','-14','0']",
  datavset1: "['-134','-134','-134','-134']",
  datavset2: "['-63','-63','-63','-63']",
  datavset3: "['2','2','2','2']",
  datavset: "['106','106','106','106']",
  classbtn: 'tp-caption text-right',
  comment: 'EXCEPTIONAL SERVICE'
 },
 {
  id: 4,
  srcimg: 'assets/img/slider/ferr.jpg',
  classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
  classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
  datax: "['right','right','right','center']",
  datahset: "['-14','-14','-14','0']",
  datavset1: "['-134','-134','-134','-134']",
  datavset2: "['-63','-63','-63','-63']",
  datavset3: "['2','2','2','2']",
  datavset: "['106','106','106','106']",
  classbtn: 'tp-caption text-right',
  comment: 'CUSTOMIZED OPTIONS'
 }
];

export default bannerhome1;
