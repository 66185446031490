import React, { Component } from 'react';
class Footer extends Component {
 render() {
  return (
   <footer id="footer" className="clearfix">
    <div id="footer-widgets" className="container">
     <div className="themesflat-row gutter-30">
      <div className="col span_1_of_3">
       <div className="widget widget_text">
        <div className="textwidget">
         <p>
          <img src="assets/img/logo.png" alt="imagealt" width={170} height={34} />
         </p>
         <p className="margin-bottom-15">
          Serving the greater area of Indianapolis Indiana, we specialize in parking management,
          traffic control , valet parking and shuttle services
         </p>
         <ul>
          <li></li>
          <li>
           <div className="inner">
            <span className="fa fa-phone" />
            <a href="tel:317-429-6781" className="">
             (317)-429-6781
            </a>
           </div>
          </li>
          <li className="margin-top-7">
           <div className="inner">
            <span className=" font-size-14 fa fa-envelope" />
            <a href="mailto:info@goldstarmanagementllc.com" className="">
             info@goldstarmanagementllc.com
            </a>
           </div>
          </li>
          <li className="margin-top-7">
           <div className="inner">
            <a className={'links'} href={'https://goldstar-website.flycricket.io/privacy.html'}>
             {' '}
             Privacy{' '}
            </a>
           </div>
          </li>
         </ul>
        </div>
       </div>
       {/* /.widget_text */}
       <div
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={0}
        data-smobile={35}
       />
      </div>
      {/* /.col */}
      <div className="col span_1_of_3">
       <div
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={0}
        data-smobile={0}
       />
       <div className="widget widget_lastest">
        <h2 className="widget-title">
         <span>NEW LOCATIONS</span>
        </h2>
        <ul className="lastest-posts data-effect clearfix">
         <li className="clearfix">
          <div className="thumb data-effect-item has-effect-icon">
           <img src="assets/img/partners/irt.png" alt="imagealt" />
           <div className="overlay-effect bg-color-2" />
          </div>
          <div className="text">
           <h3>
            <a href="https://www.irtlive.com/">Indiana Repertory Theater</a>
           </h3>
           {
            <span className="post-date">
             <span className="entry-date">Entertainment</span>
            </span>
           }
          </div>
         </li>
         <li className="clearfix">
          <div className="thumb data-effect-item has-effect-icon">
           <img src="assets/img/partners/italian.png" alt="imagealt" />
           <div className="overlay-effect bg-color-2" />
          </div>
          <div className="text">
           <h3>
            <a href="https://www.italianhousedining.com/">The Italian House</a>
           </h3>
           <span className="post-date">
            <span className="entry-date">Restaurant</span>
           </span>
          </div>
         </li>
         <li className="clearfix">
          <div className="thumb data-effect-item has-effect-icon">
           <img src="assets/img/partners/hyde.png" alt="imagealt" />
           <div className="overlay-effect bg-color-2" />
          </div>
          <div className="text">
           <h3>
            <a href="https://www.hydeparkrestaurants.com/">Hyde Park</a>
           </h3>
           <span className="post-date">
            <span className="entry-date">Restaurant</span>
           </span>
          </div>
         </li>
        </ul>
       </div>
       {/* /.widget_lastest */}
      </div>
      {/* /.col */}
      <div className="col span_1_of_3">
       <div
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={35}
        data-smobile={35}
       />
       <div className="widget widget_tags">
        <h2 className="widget-title margin-bottom-30">
         <span>TAGS</span>
        </h2>
        <div className="tags-list">
         <a href="services">Wedding Valet</a>
         <a href="services">Restaurant Valet </a>
         <a href="services">Private Event Valet</a>
         <a href="services">Hotel Valet</a>
         <a href="services">Corporate Event Valet</a>
         <a href="services">Fundraiser Valet</a>
        </div>
       </div>
      </div>
      {/* /.col */}
      <div className="col span_1_of_3">
       <div
        className="themesflat-spacer clearfix"
        data-desktop={0}
        data-mobile={35}
        data-smobile={35}
       />
       <div className="widget widget_instagram">
        <h2 className="widget-title margin-bottom-30">
         <span>SOCIAL</span>
        </h2>
        <div className=" col-xl-3  col-lg-6  col-xs-3 col-sm-3 col-md-6 ">
         <div className="widget no-box">
          <a href={'https://www.facebook.com/GoldstarManagementLLC'} className="fa fa-facebook" />
          <br />
          <a
           href={'https://twitter.com/GoldStar_MGMT/status/586702870315171840'}
           className="fa fa-twitter"
          />
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
   </footer>
  );
 }
}

export default Footer;
