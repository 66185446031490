import React, { useState, useEffect } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import { Information } from '../layouts/contact';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';
import { formatPhone } from '../../util/formInputUtils';
import { addMessageRequest } from '../layouts/services/firestoreService';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
const Contact = () => {
 const [headers, setHeaders] = useState([
  {
   id: 1,
   logoweb: 'assets/img/logos.jpg',
   names: 'Contact'
  }
 ]);
 const [banners, setBanners] = useState([
  {
   id: 1,
   links: '',
   titlelink: '',
   border: '',
   name: 'Contact',
   heading: 'CONTACT US'
  }
 ]);
 const [open, setOpen] = useState(false);
 useEffect(() => {
  const form = document.querySelector('#join-team');
  const handleClose = (event, reason) => {
   if (reason === 'clickaway') {
    return;
   }
   setOpen(false);
  };
  form.addEventListener('submit', (e) => {
   formatPhone(form.phone.value);
   e.preventDefault();
   addMessageRequest(form).then(() => {
    form.firstName.value = '';
    form.lastName.value = '';
    form.email.value = '';
    form.phone.value = '';
    form.message.value = '';
    setOpen(true);
   });
  });
 }, []);

 const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
   return;
  }

  setOpen(false);
 };

 return (
  <div className="header-fixed page no-sidebar header-style-2 topbar-style-1">
   <div id="wrapper" className="animsition">
    <Snackbar
     open={open}
     autoHideDuration={6000}
     onClose={handleClose}
     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
     <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
      Information submitted Successfully
     </Alert>
    </Snackbar>
    <div id="page" className="clearfix">
     <div id="site-header-wrap">
      <TopBar />
      {headers.map((data, idx) => (
       <Header data={data} key={idx} />
      ))}
     </div>
     {banners.map((data) => (
      <BannerTitle key={data.id} data={data} />
     ))}
     <div id="main-content" className="site-main clearfix">
      <div id="content-wrap">
       <div id="site-content" className="site-content clearfix">
        <div id="inner-content" className="inner-content-wrap">
         <div className="page-content">
          <div className="row-contact">
           <div className="container">
            <div className="row">
             <div className="col-md-12">
              <div
               className="themesflat-spacer clearfix"
               data-desktop={61}
               data-mobile={60}
               data-smobile={60}
              />
             </div>
            </div>
            <div className="row">
             <div className="col-md-6">
              <div className="themesflat-headings style-2 clearfix">
               <h2 className="heading">CONTACT US</h2>
               <div className="sep has-width w80 accent-bg clearfix" />
               <p className="sub-heading">CONTACT US FOR GENERAL QUESTIONS AND COMMENTS</p>
              </div>
              <div
               className="themesflat-spacer clearfix"
               data-desktop={36}
               data-mobile={35}
               data-smobile={35}
              />
              <div className="themesflat-contact-form style-2 clearfix">
               <form id={'join-team'} className="form-submit contact-form wpcf7-form">
                <span className="wpcf7-form-control-wrap your-name">
                 <input
                  type="text"
                  tabIndex={1}
                  id="firstName"
                  name="firstName"
                  defaultValue={''}
                  className="wpcf7-form-control"
                  placeholder="First Name*"
                  required
                 />
                </span>
                <span className="wpcf7-form-control-wrap your-phone">
                 <input
                  type="text"
                  tabIndex={2}
                  id="lastName"
                  name="lastName"
                  defaultValue={''}
                  className="wpcf7-form-control"
                  placeholder="Last Name*"
                  required
                 />
                </span>
                <span className="wpcf7-form-control-wrap your-phone">
                 <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="wpcf7-form-control"
                  placeholder="Your Phone Number*"
                  required
                 />
                </span>
                <span className="wpcf7-form-control-wrap your-email">
                 <input
                  type="email"
                  tabIndex={4}
                  id="email"
                  name="email"
                  defaultValue={''}
                  className="wpcf7-form-control"
                  placeholder="Your Email*"
                  required
                 />
                </span>
                <span className="wpcf7-form-control-wrap your-message">
                 <textarea
                  name="message"
                  tabIndex={5}
                  cols={40}
                  rows={10}
                  className="wpcf7-form-control wpcf7-textarea"
                  placeholder="Message"
                  required
                  defaultValue={''}
                 />
                </span>
                <div className="submit-but">
                 <button type="submit">Submit</button>
                </div>
               </form>
              </div>
              <div
               className="themesflat-spacer clearfix"
               data-desktop={80}
               data-mobile={60}
               data-smobile={60}
              />
             </div>{' '}
             <div className="col-md-6">
              <div
               className="themesflat-spacer clearfix"
               data-desktop={0}
               data-mobile={0}
               data-smobile={35}
              />
             </div>
            </div>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
    </div>
    <Footer />
    {headers.map((data, idx) => (
     <BottomBar data={data} key={idx} />
    ))}
   </div>
  </div>
 );
};

export default Contact;
