import React, { Component } from 'react';

class Information extends Component {
 render() {
  return (
   <div className="col-md-6">
    <div
     className="themesflat-spacer clearfix"
     data-desktop={0}
     data-mobile={0}
     data-smobile={35}
    />
    <div className="themesflat-headings style-2 clearfix">
     <h2 className="heading">CONTACT INFOMATION</h2>
     <div className="sep has-width w80 accent-bg clearfix" />
    </div>
    <div
     className="themesflat-spacer clearfix"
     data-desktop={36}
     data-mobile={35}
     data-smobile={35}
    />
    <div className="themesflat-tabs style-1 w168 clearfix">
     <ul className="tab-title clearfix"></ul>
     <div className="tab-content-wrap clearfix">
      <div className="tab-content">
       <div className="item-content">
        <ul>
         <li className="clearfix"></li>
         <li className="clearfix">
          <div className="inner">
           <span className="fa fa-phone" />
           <a href="tel:317-429-6781" className="">
            Call Us: (317)-429-6781
           </a>
          </div>
         </li>
         <li className="clearfix">
          <div className="inner">
           <span className="fa fa-envelope" />
           <a href="mailto:info@goldstarmanagementllc.com" className="">
            info@goldstarmanagementllc.com
           </a>
          </div>
         </li>
        </ul>
       </div>
      </div>
     </div>
    </div>
    <div
     className="themesflat-spacer clearfix"
     data-desktop={20}
     data-mobile={35}
     data-smobile={35}
    />
   </div>
  );
 }
}

export default Information;
