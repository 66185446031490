import React, { Component } from 'react';
import Ticker from 'react-ticker';
import $ from 'jquery';
class Partner extends Component {
 constructor(props) {
  super(props);
  this.state = {
   partner: [
    {
     id: 1,
     img: 'assets/img/partners/pianists.png'
    },
    {
     id: 2,
     img: 'assets/img/partners/cabaret.png'
    },
    {
     id: 3,
     img: 'assets/img/partners/canal337.jpg'
    },
    {
     id: 4,
     img: 'assets/img/partners/cranebay.png'
    },
    {
     id: 5,
     img: 'assets/img/partners/daniels.png'
    },
    {
     id: 6,
     img: 'assets/img/partners/symOrch.png'
    },
    {
     id: 7,
     img: 'assets/img/partners/500.png'
    },

    {
     id: 9,
     img: 'assets/img/partners/methodist.jpg'
    },
    {
     id: 10,
     img: 'assets/img/partners/tonys.jpg'
    },
    {
     id: 11,
     img: 'assets/img/partners/purdue.png'
    },
    {
     id: 12,
     img: 'assets/img/partners/ritz.jpg'
    },
    {
     id: 13,
     img: 'assets/img/partners/hulman.jpg'
    },
    {
     id: 14,
     img: 'assets/img/partners/wasserman.png'
    }
   ],
   isMobile: false
  };
 }
 componentDidMount() {
  if ($(window).width() < 600) {
   this.setState({
    isMobile: true
   });
  }
 }

 render() {
  return (
   <div className={'ticker container-fluid'}>
    {this.state.isMobile ? (
     <Ticker mode={'smooth'} speed={8} height={100}>
      {({ index }) => (
       <>
        {this.state.partner.map((data) => (
         <img src={data.img} className={''} alt={''} height={85} width={85} />
        ))}
       </>
      )}
     </Ticker>
    ) : (
     <Ticker mode={'smooth'} speed={8} height={500}>
      {({ index }) => (
       <>
        {this.state.partner.map((data) => (
         <img src={data.img} alt={''} className={'col-2'} height={225} width={225} />
        ))}
       </>
      )}
     </Ticker>
    )}
   </div>
  );
 }
}

export default Partner;
