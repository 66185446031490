import React from 'react';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import ClientList from './ClientList';

class Clients extends React.Component {
 constructor(props) {
  super(props);

  this.state = {
   photoIndex: 0,
   isOpen: false,
   headers: [
    {
     id: 1,
     logoweb: 'assets/img/logos.jpg',
     names: 'Clients'
    }
   ],
   banners: [
    {
     id: 1,
     links: '',
     titlelink: '',
     border: '',
     name: 'Clients',
     heading: 'OUR CLIENTS'
    }
   ],
   clients: [
    {
     id: 1,
     img: 'assets/img/partners/500.png'
    },
    {
     id: 2,
     img: 'assets/img/partners/hyde.png'
    },
    {
     id: 3,
     img: 'assets/img/partners/seasons.PNG'
    },
    {
     id: 4,
     img: 'assets/img/partners/sulivan.PNG'
    },
    {
     id: 5,
     img: 'assets/img/partners/eddie.PNG'
    },
    {
     id: 6,
     img: 'assets/img/partners/geraldines.jpg'
    },
    {
     id: 7,
     img: 'assets/img/partners/meridian.PNG'
    },
    {
     id: 8,
     img: 'assets/img/partners/irt.png'
    },
    {
     id: 9,
     img: 'assets/img/partners/maggianos.PNG'
    },
    {
     id: 10,
     img: 'assets/img/partners/tonys.jpg'
    },
    {
     id: 11,
     img: 'assets/img/partners/cabaret.png'
    },
    {
     id: 12,
     img: 'assets/img/partners/simons.png'
    },
    {
     id: 13,
     img: 'assets/img/partners/cristel.jpg'
    },
    {
     id: 14,
     img: 'assets/img/partners/iu.jpg'
    },
    {
     id: 15,
     img: 'assets/img/partners/methodist.jpg'
    },
    {
     id: 16,
     img: 'assets/img/partners/perry.jpg'
    },
    {
     id: 17,
     img: 'assets/img/partners/pianists.png'
    },
    {
     id: 18,
     img: 'assets/img/partners/thomas.jpg'
    },
    {
     id: 19,
     img: 'assets/img/partners/museum.png'
    },
    {
     id: 21,
     img: 'assets/img/partners/pipers.jpg'
    },
    {
     id: 22,
     img: 'assets/img/partners/jazz.png'
    },
    {
     id: 23,
     img: 'assets/img/partners/amc.jpg'
    },
    {
     id: 24,
     img: 'assets/img/partners/gleaners.png'
    },
    {
     id: 25,
     img: 'assets/img/partners/community.png'
    },
    {
     id: 26,
     img: 'assets/img/partners/IMA.png'
    },
    {
     id: 27,
     img: 'assets/img/partners/ileapng.png'
    },
    {
     id: 28,
     img: 'assets/img/partners/ISM.jpg'
    },
    {
     id: 29,
     img: 'assets/img/partners/IVY.png'
    },
    {
     id: 30,
     img: 'assets/img/partners/marvis.png'
    },
    {
     id: 31,
     img: 'assets/img/partners/nfl.png'
    },
    {
     id: 32,
     img: 'assets/img/partners/united.png'
    },
    {
     id: 33,
     img: 'assets/img/partners/heroes.png'
    },
    {
     id: 35,
     img: 'assets/img/partners/italian.png'
    },
    {
     id: 36,
     img: 'assets/img/partners/rail.png'
    },
    {
     id: 37,
     img: 'assets/img/partners/canal337.jpg'
    },
    {
     id: 38,
     img: 'assets/img/partners/cranebay.png'
    },
    {
     id: 39,
     img: 'assets/img/partners/peterson.png'
    },
    {
     id: 40,
     img: 'assets/img/partners/daniels.png'
    },
    {
     id: 41,
     img: 'assets/img/partners/symOrch.png'
    },
    {
     id: 42,
     img: 'assets/img/partners/purdue.png'
    },
    {
     id: 43,
     img: 'assets/img/partners/ritz.jpg'
    },
    {
     id: 44,
     img: 'assets/img/partners/hulman.jpg'
    },
    {
     id: 46,
     img: 'assets/img/partners/wasserman.png'
    },
    {
     id: 47,
     img: 'assets/img/partners/art.png'
    },
    {
     id: 48,
     img: 'assets/img/partners/barbie.png'
    },
    {
     id: 49,
     img: 'assets/img/partners/eiteljorg.png'
    },
    {
     id: 50,
     img: 'assets/img/partners/fhealth.png'
    },
    {
     id: 51,
     img: 'assets/img/partners/hendricks.png'
    },
    {
     id: 52,
     img: 'assets/img/partners/balet.png'
    },
    {
     id: 53,
     img: 'assets/img/partners/zoo.png'
    },
    {
     id: 54,
     img: 'assets/img/partners/smith.png'
    },
    {
     id: 55,
     img: 'assets/img/partners/davidRe.png'
    },
    {
     id: 56,
     img: 'assets/img/partners/tinker.png'
    }
   ]
  };
 }
 render() {
  // eslint-disable-next-line no-unused-vars
  const { photoIndex, isOpen } = this.state;
  return (
   <div className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search">
    <div id="wrapper" className="animsition">
     <div id="page" className="clearfix">
      <div id="site-header-wrap">
       <TopBar />
       {this.state.headers.map((data, idx) => (
        <Header data={data} key={idx} />
       ))}
      </div>
      {this.state.banners.map((data) => (
       <BannerTitle key={data.id} data={data} />
      ))}
      <div className={'container-fluid'}>
       <ClientList clients={this.state.clients} />
       <div
        className="themesflat-spacer clearfix"
        data-desktop={80}
        data-mobile={60}
        data-smobile={60}
       />
      </div>
     </div>
     <Footer />
     {this.state.headers.map((data, idx) => (
      <BottomBar data={data} key={idx} />
     ))}
    </div>
   </div>
  );
 }
}

export default Clients;
