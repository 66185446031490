import React, { Component } from 'react';
class Testimonial extends Component {
 constructor(props) {
  super(props);
  this.state = {
   testimonial: [
    {
     id: 1,
     text: (
      <q className={''}>
       I had the opportunity to work with Goldstar for a private party. They had to navigate a
       narrow driveway, and then park cars across one of the busiest streets in town. They handled
       it perfectly and treated each guest with superb customer service. As the event planner, they
       went above and beyond to help with anything else I needed. Excellent customer service. Will
       definitely be using them again!
      </q>
     ),
     name: 'Brittney Grim '
    },
    {
     id:1,
     text: (
      <q>
       {' '}
       Goldstar handled valet services for our son's wedding. They dealt with a complicated
       entryway/parking lot, arrival of many vehicles during a short period of time, and departures
       over many hours. The process was flawless, the service superb, and not a single complaint.
       We'll be using Goldstar for our next event and recommending them to others.
      </q>
     ),
     name: 'Bruce Hetrick'
    },
    {
     id: 3,
     text: (
      <q className={''}>
       Thank you so much for sponsoring ts,he ILEA Indiana Holiday Luncheon early this month at The
       Manor at The Children's Museum of Indianapolis! We wouldn't have had a successful event
       without your help!
      </q>
     ),
     name: 'Megan Johnston'
    },
    {
     id: 3,
     text: (
      <q className={''}>
       A terrific option for any events that need valet parking! The employees are so courteous and
       do everything they can to make sure things run smoothly. 10/10 would recommend.
      </q>
     ),
     name: 'Michael C'
    }
   ]
  };
 }

 render() {
  return (
   <div className="row-testimonials parallax-2">
    <div className="container">
     <div className="row">
      <div className="col-md-12">
       <div
        className="themesflat-spacer clearfix"
        data-desktop={80}
        data-mobile={60}
        data-smobile={60}
       />
       <div
        className="themesflat-carousel-box has-arrows arrow-center arrow-circle offset-v-24 clearfix"
        data-gap={30}
        data-column={1}
        data-column2={1}
        data-column3={1}
        data-auto="false">
        <div className="owl-carousel owl-theme">
         {this.state.testimonial.map((data) => (
          <div
           className="themesflat-testimonials style-1 max-width-70 align-center has-width w100 circle border-solid clearfix"
           key={data.id}>
           <div className="testimonial-item">
            <div className="inner">
             <div className="icon-wrap">
              <i className="fa fa-quote-left" />
             </div>
             <blockquote className="text">
              <p>{data.text}</p>
              <div className="sep has-width w80 accent-bg clearfix" />
              <h6 className="name">{data.name}</h6>
             </blockquote>
            </div>
           </div>
          </div>
         ))}
        </div>
       </div>
       <div
        className="themesflat-spacer clearfix"
        data-desktop={68}
        data-mobile={60}
        data-smobile={60}
       />
      </div>
     </div>
    </div>
   </div>
  );
 }
}

export default Testimonial;
