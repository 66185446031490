import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const ClientList = (props) => {
  const clientListPics = props.clients;
  return (
    <div className={'container-fluid'}>
      <MDBContainer>
        <div className="mdb-lightbox no-margin">
          <MDBRow>
            {clientListPics.map((data) => (
              <MDBCol md="3">
                <figure>
                  <img
                    src={data.img}
                    alt="Gallery"
                    className="img-fluid"
                    height={200}
                    width={200}
                  />
                </figure>
              </MDBCol>
            ))}
          </MDBRow>
        </div>
      </MDBContainer>
    </div>
  );
};

export default ClientList;
