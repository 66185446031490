import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
export async function addJoinRequest(form) {
 try {
  const docData = {
   firstName: form.firstName.value,
   lastName: form.lastName.value,
   email: form.email.value,
   phone: form.phone.value,
   message: form.message.value,
   requestedOn: Timestamp.fromDate(new Date())
  };
  console.log('phone', form.phone.value);
  await addDoc(collection(db, 'joinRequests'), docData);
 } catch (e) {
  alert(
   'There was an issue submitting the information. call as at (317)-429-6781 to talk to us directly'
  );
  return console.error('Error adding document: ', e);
 }
}

export async function addQuoteRequest(form) {
 try {
  const docData = {
   eventDate: form.eventDate.value,
   eventTime: form.eventTime.value,
   address: {
    street: form.eventStreetAddress.value,
    city: form.eventCity.value,
    state: form.eventState.value,
    zip: form.eventZipCode.value
   },
   eventGuests: form.eventGuests.value,
   eventInfo: form.message.value,
   firstName: form.firstName.value,
   lastName: form.lastName.value,
   email: form.email.value,
   phone: form.phone.value,
   requestedOn: Timestamp.fromDate(new Date())
  };
  await addDoc(collection(db, 'quoteRequets'), docData);
 } catch (e) {
  alert(
   'There was an issue submitting the information. call as at (317)-429-6781 to talk to us directly'
  );
  console.error('Error adding document: ', e);
 }
}
export async function addMessageRequest(form) {
 try {
  const docData = {
   firstName: form.firstName.value,
   lastName: form.lastName.value,
   email: form.email.value,
   phone: form.phone.value,
   message: form.message.value,
   sentOn: Timestamp.fromDate(new Date())
  };
  await addDoc(collection(db, 'messageRequests'), docData);
 } catch (e) {
  alert(
   'There was an issue submitting the information. call as at (317)-429-6781 to talk to us directly'
  );
  return console.error('Error adding document: ', e);
 }
}
