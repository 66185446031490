import Home from './Home01';
import Services from './Services';
import Contact from './Contact';
import Join from './Join';
import Leadership from './Leadership';
import Clients from './Clients';
import Quote from './Quote';

const routes = [
 { path: '/', component: Home },
 { path: '/services', component: Services },
 { path: '/join', component: Join },
 { path: '/quote', component: Quote },
 { path: '/clients', component: Clients },
 { path: '/leadership', component: Leadership },
 { path: '/contact', component: Contact }
];

export default routes;
