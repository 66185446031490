import React, { Component } from 'react';

class TopBar extends Component {
 render() {
  return (
   <div id="top-bar">
    <div id="top-bar-inner" className="container">
     <div className="top-bar-inner-wrap">
      <div className="top-bar-content">
       <div className="inner">
        <span className="phone content">
         <a href="tel:317-429-6781" className="">
          (317)-429-6781
         </a>
        </span>
        <span className="fa fa-envelope">
         <a href="mailto:info@goldstarmanagementllc.com" className="">
          info@goldstarmanagementllc.com
         </a>
        </span>{' '}
       </div>
      </div>
      <div className="top-bar-socials">
       <div className="inner">
        <span className="text">Follow us:</span>
        <span className="icons">
         <a href="https://www.facebook.com/GoldstarManagementLLC">
          <i className="fa fa-facebook" />
         </a>
         <a href="https://twitter.com/GoldStar_MGMT/status/586702870315171840">
          <i className="fa fa-twitter" />
         </a>
        </span>
       </div>
      </div>
     </div>
    </div>
   </div>
  );
 }
}

export default TopBar;
