import React, { Component } from 'react';
import {
 Slider,
 TitleHeading,
 IconBox,
 About,
 Testimonial,
 Partner
} from '../layouts/home01/index';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';

class Home extends Component {
 constructor(props) {
  super(props);
  this.state = {
   headers: [
    {
     id: 1,
     logoweb: 'assets/img/logos.jpg',
     names: 'Home'
    }
   ],
   headingchoose: [
    {
     id: 1,
     classnames: 'heading',
     title: 'Goldstar',
     text: '',
     classtext: 'sub-heading'
    }
   ],
   headingservice: [
    {
     id: 1,
     classnames: 'heading',
     title: 'SERVICES',
     text:
      'Goldstar provides a wide variety of services. We handle weddings, private parties, fundraisers, restaurants, sporting events, hotels, shuttling services, parking management services, grand openings, corporate events and many more.',
     classtext: 'sub-heading'
    }
   ],
   headingproject: [
    {
     id: 1,
     classnames: 'heading text-white',
     title: 'FEATURED PROJECT',
     classtext: 'sub-heading'
    }
   ],
   servicebox: [
    {
     id: 1,
     classnames: 'heading font-size-30',
     title: 'ALL SERVICES',
     classtext: 'sub-heading font-weight-400',
     text:
      'Are you interested in finding out how we can make your project a success? Please constact us.'
    }
   ]
  };
 }

 render() {
  return (
   <div className="header-fixed page no-sidebar header-style-2 topbar-style-2 menu-has-search">
    <div id="wrapper" className="animsition">
     <div id="page" className="clearfix">
      <div id="site-header-wrap">
       <TopBar />
       {this.state.headers.map((data, idx) => (
        <Header data={data} key={idx} />
       ))}
      </div>
      <div id="main-content" className="site-main clearfix">
       <div id="content-wrap">
        <div id="site-content" className="site-content clearfix">
         <div id="inner-content" className="inner-content-wrap">
          <div className="page-content">
           <div className="rev_slider_wrapper fullwidthbanner-container">
            <div id="rev-slider1" className="rev_slider fullwidthabanner">
             <Slider />
            </div>
           </div>
           <div className="row-iconbox">
            <div className="container">
             <div className="row">
              <div className="col-md-12">
               <div
                className="themesflat-spacer clearfix"
                data-desktop={60}
                data-mobile={60}
                data-smobile={60}
               />
               {this.state.headingchoose.map((data) => (
                <TitleHeading data={data} key={data.id} />
               ))}
               <div
                className="themesflat-spacer clearfix"
                data-desktop={42}
                data-mobile={35}
                data-smobile={35}
               />
              </div>
             </div>

             <IconBox />
             <div className="row">
              <div className="col-md-12">
               <div
                className="themesflat-spacer clearfix"
                data-desktop={41}
                data-mobile={35}
                data-smobile={35}
               />
              </div>
             </div>
            </div>
           </div>
           <About />
           <div className="row-services">
            <div className="container">
             <div className="row">
              <div className="col-md-12">
               <div
                className="themesflat-spacer clearfix"
                data-desktop={60}
                data-mobile={60}
                data-smobile={60}
               />
               {this.state.headingservice.map((data) => (
                <TitleHeading data={data} key={data.id} />
               ))}
               <div
                className="themesflat-spacer clearfix"
                data-desktop={39}
                data-mobile={35}
                data-smobile={35}
               />
               <div
                className="themesflat-spacer clearfix"
                data-desktop={50}
                data-mobile={35}
                data-smobile={35}
               />
               <div className="elm-button text-center">
                <a href="services" className="themesflat-button bg-accent">
                 SEE ALL SERVICES
                </a>
               </div>
               <div
                className="themesflat-spacer clearfix"
                data-desktop={73}
                data-mobile={60}
                data-smobile={60}
               />
              </div>
             </div>
            </div>
           </div>

           <Testimonial />
           <div
            className="themesflat-spacer clearfix"
            data-desktop={73}
            data-mobile={60}
            data-smobile={60}
           />
           <Partner />
           <div
            className="themesflat-spacer clearfix"
            data-desktop={61}
            data-mobile={60}
            data-smobile={60}
           />
           <div className="elm-button text-center">
            <a href="clients" className="themesflat-button bg-accent">
             SEE ALL CLIENTS
            </a>
           </div>
          </div>
         </div>
        </div>
       </div>
      </div>
      <Footer />
      {this.state.headers.map((data, idx) => (
       <BottomBar data={data} key={idx} />
      ))}
     </div>
    </div>
   </div>
  );
 }
}

export default Home;
