const menus = [
 {
  id: 1,
  name: 'Home',
  links: '/'
 },
 {
  id: 2,
  name: 'OUR TEAM',
  namesub: [
   {
    id: 1,
    sub: 'JOIN OUR TEAM',
    links: '/join'
   },
   {
    id: 2,
    sub: 'Executive-Leadership',
    links: '/leadership'
   }
  ]
 },
 {
  id: 3,
  name: 'Services',
  links: '/services'
 },
 {
  id: 4,
  name: 'GET A QUOTE',
  links: '/quote'
 },
 {
  id: 5,
  name: 'Clients',
  links: '/clients'
 },
 {
  id: 6,
  name: 'Contact',
  links: '/contact'
 }
];

export default menus;
